.Service {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px 10px;
  width: 350px;
  min-height: 250px;
  flex-grow: 1;
  transition: all 0.2s;
}

.service-logo {
  height: 50px;
  margin-bottom: 10px;
}

.Service h4 {
  color: #a466c9;
  text-align: center;
  margin-bottom: 20px;
}

.Service p {
  text-indent: 20px;
}

.Service:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
}
