.ViewResumee.green .digital-skills ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.ViewResumee.green .digital-skills ul li {
    margin: 5px;
    background-color: rgba(34, 168, 96,0.1);
    color: rgba(34, 168, 96);
    padding: 3px 5px;
    font-style: italic;
    border-radius: 4px;
}

.ViewResumee.green .licence-type{
    font-weight: bolder;
    color:gray;
}

.ViewResumee.green .licence{
    margin: 5px;
    background-color: rgba(34, 168, 96,0.1);
    color: rgba(34, 168, 96);
    padding: 3px 5px;
    font-style: italic;
    border-radius: 4px;
}

.ViewResumee.green .edu-title {
    margin-bottom: 2px;
}

.ViewResumee.green .edu-school {
    margin-top: 0px;
    color: rgb(160, 160, 160);
    text-indent: 10px;
    font-style: italic;
}

.ViewResumee.green .edu-location {
    margin-left: 10px;
    color: rgb(117, 117, 117);
}

.ViewResumee.green .edu-city-country{
    font-weight: bolder;
    color: rgb(117, 117, 117);
    font-style: italic;
}


.ViewResumee.green .header-info div {
    display: flex;
    margin-top: 20px;
}

.ViewResumee.green .header-info div p {
    margin-right: 20px;
}

.ViewResumee.green .titles {
    font-weight: bold;
}

.ViewResumee.green .icon {
    max-width: 15px;
    max-height: 15px;
    margin-right: 4px;
}

.ViewResumee.green .about-me-title {
    color: rgba(34, 168, 96,0.8);
}
.ViewResumee.green .about-me-desc {
    text-indent: 3%;
    text-align: justify;
}

.ViewResumee.green .name {
    color: rgba(34, 168, 96);
}

.ViewResumee.green .honours-issuer{
    margin-top: 0px;
    color: rgb(160, 160, 160);
    text-indent: 10px;
    font-style: italic;
}

.ViewResumee.green .language-type {
    font-weight: bolder;
}

.ViewResumee.green .language-first-row, .ViewResumee.green .language-second-row{
    display: flex;
}

.ViewResumee.green .language-headers {
    font-weight: bolder;
    color: gray;
    margin-left: 10px;
    background-color: white;
}

.ViewResumee.green .language-levels {
    margin: 5px;
    background-color: rgba(34, 168, 96,0.1);
    color: rgba(34, 168, 96);
    padding: 3px 5px;
    font-style: italic;
    border-radius: 4px;
}

.ViewResumee.green .project-title {
    margin-bottom: 2px;
}

.ViewResumee.green .work-experience {
    margin-bottom: 60px;
}
.ViewResumee.green .position {
    margin-bottom: 2px;
}
.ViewResumee.green .company {
    margin-top: 0px;
    color: rgb(160, 160, 160);
    text-indent: 10px;
    font-style: italic;
}

.ViewResumee.green .date {
    color: rgb(117, 117, 117);
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
}

.ViewResumee.green .technologies-title {
    margin-left: 10px;
    color: rgb(117, 117, 117);
}

.ViewResumee.green .technologies {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.ViewResumee.green .technologies li {
    margin: 5px;
    background-color: rgba(34, 168, 96,0.1);
    color: rgba(34, 168, 96);
    padding: 3px 5px;
    font-style: italic;
    border-radius: 4px;
}

.ViewResumee.green .city-country {
    font-weight: bolder;
    color: rgb(117, 117, 117);
    font-style: italic;
}

.ViewResumee.green .location {
    margin-left: 10px;
    color: rgb(117, 117, 117);
}

.ViewResumee.green .sub-title {
    color: rgba(34, 168, 96,0.8);
    margin-top: 60px;
    border-bottom:  2px solid rgba(34, 168, 96,0.8);;
    max-width: 80%;
}

.ViewResumee.green .resumee h3, .ViewResumee.green .resumee h4, .ViewResumee.green .resumee h5, .ViewResumee.green .resumee p, .ViewResumee.green .resumee div {
    margin-left: 20px;
}

.ViewResumee.green .icon {
    filter: brightness(0) saturate(100%) invert(61%) sepia(56%) saturate(7157%) hue-rotate(124deg) brightness(102%) contrast(73%);
}

.ViewResumee.green {
    border: 4px solid rgba(34, 168, 96);
}

.ViewResumee.green .pdf-icon{
    filter: brightness(0) saturate(100%) invert(61%) sepia(56%) saturate(7157%) hue-rotate(124deg) brightness(102%) contrast(73%);
}