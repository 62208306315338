html {
  height: 100%; /* Ensure html covers the entire viewport */
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh; /* Ensure the body covers at least the full viewport height */
  margin: 0;
  padding: 0;
  background: linear-gradient(
    45deg,
    rgba(249, 205, 96, 0.8) 5%,
    rgba(222, 61, 66, 0.8) 29%,
    rgba(177, 68, 183, 0.8) 77%,
    rgba(102, 59, 192, 0.8) 100%
  );
  background-attachment: fixed; /* Make background fixed while scrolling */
  background-repeat: no-repeat;
  background-size: cover; /* Ensure the background fills the screen */
  transition: all 0.5s ease-in-out;
}

.App-header {
  background-color: rgba(255, 255, 255, 0.4);
}

.App-services {
  background-color: rgba(255, 255, 255, 0.4);
}

.App-content {
  display: flex;
  flex-direction: column;
}

@media print {
  html,
  body,
  .App {
    background: none;
    background-color: white;
    border: none;
    box-shadow: none;
    visibility: hidden;
  }

  .App {
    visibility: hidden;
  }
}
