.Social {
    min-height: 80px;
    background-color: rgb(255, 255, 255, 0.4);
    position: relative;
}

.Social img {
    width: 30px;
    height: 30px;
    transition: all 0.2s ease-in-out;
}

.Social img:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.Social-details {
    position: absolute;
    bottom: 5px; /* Start from the bottom of the main container */
    left: 0;
    width: 100%; /* Ensure it stretches full width */
    transform: translateY(100%); /* Move it below the container */
    transition: transform 0.3s ease; /* Smooth transition */
    
  }
  
  .Social-details.show {
    transform: translateY(0); /* Bring it up on show */
  }