.Cv {
    min-height: 60px;
    background-color: rgb(255, 255, 255, 0.4);
}
.Cv img {
    height: 50px;
    width: 50px;
    transition: all 0.2s ease-in-out;
}

.Cv img:hover {
    cursor: pointer;
    transform:scale(1.1);
}

.hidden {
    display: none;
}

.view-cv-container {
    position: absolute;
    top:0;
    left:0;
}

.cv-close-button{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 30px;
    right: 30px;
    border-radius: 50%;
    background-color: rgb(128, 128, 128, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    text-align: center;
    color: white;
    transition: all 0.3s ease-in-out;
}

.cv-close-button:hover {
    transform: scale(1.1);
    cursor: pointer;
}