.ViewResumee {
    margin: 20px;
    padding: 20px 50px;
    border-radius: 20px;
    background-color: white;
}


.purple-button, .orange-button, .gray-button, .green-button, .blue-button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    margin: 5px;
    transition: all 0.5s;
}

.purple-button:hover, .orange-button:hover, .gray-button:hover, .green-button:hover, .blue-button:hover {
    opacity: 0.5;
    transform: scale(1.1);
    border: none;
    cursor: pointer;
}

.purple-button {
    background-color: rgba(177,68,183);
}

.orange-button {
    background-color: rgba(238, 107, 82);
}

.gray-button {
    background-color: rgba(172, 180, 171);
}

.green-button {
    background-color: rgba(34, 168, 96);
}

.blue-button {
    background-color: rgba(33, 150, 243);
}

.resumee-container{
    background-color: rgb(255, 255, 255, 0.4);
    border-radius: 10px;
    display: flex;
    padding: 15px;
    margin: 15px 0px 15px 15px;
    flex-direction: column;
  }

.resumee-ico {
    width: 25px;
    transition: all 0.2s;
}

.resumee-ico:hover{
    transform: scale(1.1);
    cursor:pointer;
}

.resumee-popup {
    width: 95%;
    height: 95%;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    display: flex;
    justify-content: center;
    
}

.resumee {
    margin: 20px;
    max-width: 80%;
    overflow:auto;
    padding-right: 20px;
}



.close-resumee {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    font-size: 24px;
    color: grey;
    transition: transform 0.3s, font-weight 0.3s;
}

.close-resumee:hover {
    transform: scale(1.1);
    cursor: pointer;
    font-weight: bold;
}

.hide-resumee {
    visibility: hidden;
}

.download-pdf {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 40px;
    transition: all 0.2s;
}

.download-pdf:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.education-and-training, .work-experience, .language-skills, .honours-and-awards, .driving-licence, .digital-skills, .projects{
    margin-left: 30px;
}

.pdf-icon{
    width: 40px;
    height: 40px;
    transition: transform 0.5s, opacity 0.5s; /* Correct transition shorthand */
}

.pdf-icon:hover{
    opacity: 0.7;
    transform: scale(1.1);
    cursor:pointer;
}

.ViewResumee-colors-print{
    display: flex;
    justify-content: space-between;
}


@media only screen and (max-width: 710px) {
    .resumee-container {
        margin: initial;
        justify-content: center;
        align-items: center;
    }
    .resumee-ico {
        width: 50px;

    }

    .resumee-popup{
        padding-top: 50px;
    }

    .resumee {
        margin-left: 0px;
        margin-right: 0px;
        min-width: 99%;
    }

}

@media print {
    .resumee-container {
        min-width: 90%;
    }
    .resumee {
        min-width: 90%;
    }
    .ViewResumee-subcontainer {
        min-width: 90%;
    }
    .ViewResumee {
        min-width: 102vw;
        position: absolute;
        top:-10px;
        left: -10px;
        margin: 0;
        padding: 20px;
        padding-right: 50px;
    }
}

@media only screen and (max-width: 890px) {
    .header-subcontainer {
        flex-direction: column;
    }
}