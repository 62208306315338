@media print {
    .ViewResumee-subcontainer {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        background-color: white;
        padding-right: 20px;
        padding-left: 20px;
    }
    .DisplayUsername {
        display: none;
    }

}