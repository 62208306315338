.project-button {
  background-color: rgb(255, 255, 255, 0.2);
  transition: all 0.2s ease-in-out;
  border: none;
  margin: 10px;
  padding: 5px;
  color: white;
  border-radius: 10px;
}

.project-button:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.8);
}

.ProjectsMenu {
  flex-wrap: wrap;
  justify-content: space-around;
}

.Project {
  text-align: justify;
  text-indent: 20px;
  background-color: rgb(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 10px;
}

.ProjectTitle {
  text-indent: 0;
  text-decoration: underline;
}

.projects-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

.domain-category {
  color: white;
  margin-top: 20px;
  border-bottom: none;
}
