.testimonial {
    text-align: justify;
}

.testimonial-person {
    text-align: right;
    margin-right: 20px;
    font-style: italic;
    font-weight: bold;
  color: #555;
}

.Testimonial-container{
    background-color: rgba(255, 255, 255, 0.24);
    border-radius: 20px;
    
}