.Services-container {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Services h2 {
  color: white;
  margin: 10px;
}
